import { getFirestore } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import {
	connectFunctionsEmulator,
	getFunctions,
	httpsCallable,
} from "firebase/functions";

import {
	OAuthProvider,
	getAuth,
	signInWithPopup,
	updateProfile,
	verifyBeforeUpdateEmail,
} from "firebase/auth";
import {
	ReCaptchaEnterpriseProvider,
	initializeAppCheck,
} from "firebase/app-check";
import { getAnalytics } from "firebase/analytics";

const config = {
	apiKey: "AIzaSyDQ1htKcotmNYClKlO2-qPtcjdbgip3OoY",
	authDomain: "polish-1afa6.firebaseapp.com",
	projectId: "polish-1afa6",
	storageBucket: "polish-1afa6.appspot.com",
	messagingSenderId: "787703878649",
	appId: "1:787703878649:web:d7f9879cd86c0fcd44a328",
	measurementId: "G-SVYGYKJYFQ",
};

const app = initializeApp(config);

const functions = getFunctions(app);

export const auth = getAuth(app);

getAnalytics(app);

export const convertLessonsSnapshotToMap = snapshot => {
	const transformedLessons = snapshot.docs.map(doc => {
		return doc.data();
	});

	return transformedLessons;
};

export var googleProvider = new OAuthProvider("google.com");

export var appleProvider = new OAuthProvider("apple.com");

export const signInWithGoogle = () => {
	return signInWithPopup(auth, googleProvider);
};

export const signInWithApple = () => {
	return signInWithPopup(auth, appleProvider);
};

export const firebaseAuth = () => auth;

export const firestore = getFirestore(app);

if (process.env.NODE_ENV === "development") {
	connectFunctionsEmulator(functions, "localhost", 5001);
	// connectFirestoreEmulator(firestore, "localhost", 8080);
	// connectAuthEmulator(auth, "http://localhost:9099");

	window.FIREBASE_APPCHECK_DEBUG_TOKEN =
		process.env.REACT_APP_APP_CHECK_DEBUG_KEY;
}

initializeAppCheck(app, {
	provider: new ReCaptchaEnterpriseProvider(
		"6Ler4AgoAAAAANC5xDsppsbxt1NFqBiTZLMW4YIZ"
	),
	// Set to true to allow auto-refresh
	isTokenAutoRefreshEnabled: true,
});

export const getSecureData = httpsCallable(functions, "getSecureData_v2_0");

export const getLiveLessonUrls = httpsCallable(
	functions,
	"getLiveLessonUrls_v2_0"
);

export const createStripeCheckoutSession = httpsCallable(
	functions,
	"createStripeCheckoutSession"
);

export const getRevenuCatOffer = httpsCallable(
	functions,
	"getRevenueCatOffer_v2_0"
);

export const getUserEntitlements = httpsCallable(
	functions,
	"getUserEntitlements_v2_0"
);

export const changeStripeSubscription = httpsCallable(
	functions,
	"changeStripeSubscription"
);

export const cancelStripeSubscription = httpsCallable(
	functions,
	"cancelStripeSubscription"
);

export const getSubscriptionPlatfromAttribute = httpsCallable(
	functions,
	"getSubscriptionPlatfromAttribute_v2_0"
);

export const CreateZoomSignature = httpsCallable(
	functions,
	"CreateZoomSignature"
);

export const updateUsersProfile = data => {
	const auth = getAuth();

	return updateProfile(auth.currentUser, data);
};

export const updateUsersEmail = async email => {
	const auth = getAuth();
	await setUpdateEmailBeforeLogIn();
	await verifyBeforeUpdateEmail(auth.currentUser, email, {
		url: "https://app.swiss-german-online.com/",
	});
};

export const setUpdateEmailBeforeLogIn = httpsCallable(
	functions,
	"setUpdateEmailBeforeLogIn_v2_0"
);

export const hasAlreadySubscribedFBFunction = httpsCallable(
	functions,
	"checkIfHasAlreadySubscribed_v2_0"
);

/**
 * get all not expired subscriptions. Format: {sub_id:{cancels:true,expires_date:date}}
 */
export const getCurrentSubscriptionProductIDs = httpsCallable(
	functions,
	"getCurrentSubscriptionProductIDs_v2_0"
);

export const createStripeCardUpdateCheckoutSession = httpsCallable(
	functions,
	"createStripeCardUpdateCheckoutSession"
);

export const getFlashcardsCloudFunction = httpsCallable(
	functions,
	"getFlashcardsCloudFunction"
);

export const getGrammarPDFurl = httpsCallable(functions, "getGrammarPDFurl");

export const requestAccountDeletion = httpsCallable(
	functions,
	"requestAccountDeletion"
);

export const uncancelStripeSubscription = httpsCallable(
	functions,
	"uncancelStripeSubscription"
);
